<template>
    <div class="h-100">
        <el-container class="h-100">
            <el-aside width="320px" class="bg-white border-right">
                <el-container class="h-100">
                    <el-header height="auto" class="p-3 border-bottom border-light">文件夹</el-header>
                    <el-main class="p-0 narrow-scrollbar">
                        <el-tree :data="logDirectoryTreeNodes" node-key="value" highlight-current default-expand-all
                            :expand-on-click-node="false" @current-change="selectLogDirectoryTreeNodes" />
                    </el-main>
                </el-container>
            </el-aside>
            <el-main>
                <el-card shadow="never" :header="logDirectoryId?logDirectoryId:'日志'">
                    <el-table :data="logFiles" :show-header="false">
                        <el-table-column>
                            <template #default="scope">
                                <a :href="'/api/SystemLog/Detail?path='+scope.row.path"
                                    target="_blank">{{scope.row.name}}</a>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                logDirectoryTreeNodes: [],
                logDirectoryId: null,
                logDirectoryName: null,
                logFiles: [],
            };
        },
        methods: {
            async loadLogDirectoryTreeNodes() {
                let response = await this.$axios.get('/api/SystemLog/GetLogDirectoryTreeNodes');
                this.logDirectoryTreeNodes = response.data;
            },
            selectLogDirectoryTreeNodes(data) {
                this.logDirectoryId = data.value;
                this.logDirectoryName = data.label;
                this.loadLogFiles();
            },
            async loadLogFiles() {
                let response = await this.$axios.get('/api/SystemLog/GetLogFiles', {
                    params: {
                        path: this.logDirectoryId,
                    }
                });
                this.logFiles = response.data;
            },
        },
        created() {
            this.loadLogDirectoryTreeNodes();
        },
    };
</script>